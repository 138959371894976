@charset "UTF-8";
/* =============================================================================


     pallet


============================================================================= */
/* =============================================================================


     color


============================================================================= */
/* $header-width: 216px; */
/* =============================================================================


     index


============================================================================= */
.contents-head {
  letter-spacing: 18px;
}

.contents-head span {
  letter-spacing: normal;
}

.more-btn {
  color: #3fa9f5;
  transition: 0.2s;
  font-size: 1.8rem;
  margin-top: 1em;
  display: inline-block;
}

.more-btn:hover {
  opacity: 0.7;
}

@media (max-width: 767px) {
  .more-btn {
    font-size: 1.6rem;
  }
}

.mv {
  height: 980px;
  position: relative;
  background-image: url(../images/index/mv.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 767px) {
  .mv {
    height: 400px;
  }
}

.mv-ttl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  text-align: center;
  font-feature-settings: normal;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}

@media (max-width: 767px) {
  .mv-ttl {
    max-width: 100%;
    width: 80%;
    height: calc(100% - 80px);
    top: calc(50% + 40px);
  }
}

.mv-nav {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: calc(50% - (310px / 2));
  background: rgba(74, 129, 216, 0.8);
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .mv-nav {
    display: none;
  }
}

/* **************************************


     about


***************************************/
.about-container {
  display: flex;
  justify-content: space-between;
}

.about-container._sec {
  flex-direction: row-reverse;
}

@media (max-width: 767px) {
  .about-container._sec {
    flex-direction: column-reverse;
  }
}

@media (max-width: 767px) {
  .about-container {
    flex-direction: column-reverse;
  }
}

.about-item {
  margin-top: 32px;
}

.about-item-img {
  max-width: 472px;
  width: 100%;
}

@media (max-width: 767px) {
  .about-item-img {
    margin: 24px auto 0;
  }
}

.about-item-txtbox {
  max-width: 540px;
  width: 100%;
}

@media (max-width: 767px) {
  .about-item-txtbox {
    max-width: 100%;
  }
}

.about-ttl {
  font-size: 3rem;
  font-family: kozuka-mincho-pr6n, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .about-ttl {
    font-size: 2rem;
  }
}

.about-txt {
  line-height: 2;
}

/* **************************************


     works


***************************************/
.works {
  overflow: hidden;
  position: relative;
  z-index: 1;
  text-align: center;
}

.works:before {
  width: 100vw;
  min-width: 1920px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  content: "";
  background-image: url(../images/index/bg01.jpg);
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
}

.works-txt {
  font-size: 2rem;
  margin-top: 32px;
}

@media (max-width: 767px) {
  .works-txt {
    font-size: 1.8rem;
    margin-top: 24px;
  }
}

.works-list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .works-list {
    flex-direction: column;
  }
}

.works-item {
  width: calc(33.3333333% - (128px / 3));
  margin-top: 60px;
}

@media (max-width: 767px) {
  .works-item {
    width: 100%;
    max-width: 500px;
    margin: 32px auto 0;
  }
}

.works-img {
  position: relative;
  width: 100%;
}

.works-img:before {
  content: "";
  display: block;
  padding-top: 100%;
  /* 高さを幅の75%に固定 */
}

.works-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit:cover";
}

.works-item-box {
  text-align: left;
}

.works-date {
  margin-top: 16px;
  font-size: 2rem;
}

@media (max-width: 767px) {
  .works-date {
    font-size: 1.6rem;
  }
}

.works-item-ttl {
  margin-top: 8px;
  font-size: 2rem;
}

@media (max-width: 767px) {
  .works-item-ttl {
    font-size: 1.6rem;
  }
}

.works-item-txt {
  margin-top: 24px;
}

@media (max-width: 767px) {
  .works-item-txt {
    margin-top: 16px;
  }
}

.works-btn {
  background: #4a81d8;
  border-radius: 20px;
  color: #fff;
  margin: 60px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 316px;
  height: 50px;
  font-size: 2.2rem;
  text-align: center;
  transition: 0.2s;
}

.works-btn:hover {
  opacity: 0.7;
}

@media (max-width: 767px) {
  .works-btn {
    margin: 40px auto 0;
    width: 240px;
    height: 40px;
    font-size: 1.6rem;
  }
}
